import type { AxiosPromise } from 'axios'

import type {
  DocumentsApiGetDocumentsRequest as GetDocumentsParams,
  FullUpdateDto as CreateDocumentParams,
  DocumentsOutput,
  DocumentModel,
} from '@b-stock/location-api-client'

import appConfig from '@config/config'
import { flattenAPIParams } from '@helpers/listifyAPIParam'
import { post, get, deleteReq } from '@helpers/xhr'

const url = appConfig.api.locations.document

export default {
  get: (id: string): AxiosPromise<DocumentModel> => get(url, { id }),
  create: (data: CreateDocumentParams): AxiosPromise<DocumentModel> =>
    post(url, data),
  deleteByDocId: (id: string) => deleteReq(`${url}/${id}`),
  getDocuments: async (
    params: GetDocumentsParams
  ): Promise<DocumentsOutput> => {
    const { data } = await get(url, flattenAPIParams(params))
    return data
  },
}
