import type {
  LocationsApiGetLocationsRequest as LocationsFilterParams,
  LocationsOutput as LocationsResponse,
  LocationsInput as LocationCreateParams,
  PartialLocationUpdateDto as LocationUpdateParams,
  AddressesApiValidateAddressRequest as ValidateRequest,
  DocumentsApiGetDocumentsRequest as DocumentFilterParams,
  DocumentsOutput as DocumentsResponse,
  ValidateAddressResponse,
  CountriesOutput,
  CountryOutput,
  DocumentsApiCreateResaleCertificateRequest as GenerateCertificateParams,
  LocationModel,
} from '@b-stock/location-api-client'

import appConfig from '@config/config'
import { deleteReq, get, patch, post } from '@helpers/xhr'

const { api } = appConfig

export type UpdatedLocation = {
  id: string
  data: LocationUpdateParams
  originalId?: string
}

// This is terrible, remove it when we get our lives together on
// country/province codes
const upperProvinceCountryCodes = <
  T extends { countryCode?: string; provinceCode?: string },
>({
  countryCode,
  provinceCode,
  ...rest
}: T): T =>
  // Not ideal, but the existence of this function at all is not ideal either
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  ({
    ...rest,
    countryCode: countryCode?.toUpperCase?.(),
    ...(provinceCode && { provinceCode: provinceCode?.toUpperCase?.() }),
  }) as T

export default {
  async getAddress({ id }: { id: string }): Promise<LocationModel> {
    const { data } = await get(api.locations.id.default, null, {
      entities: { id },
    })
    return data
  },
  async fetchAddresses({
    address,
  }: {
    address: LocationsFilterParams
  }): Promise<LocationsResponse> {
    const { data } = await get(api.locations.default, {
      limit: 100,
      ...(address && upperProvinceCountryCodes(address)),
    })

    return data
  },
  async createAddress(data: LocationCreateParams) {
    const { data: response } = await post(
      api.locations.default,
      upperProvinceCountryCodes(data)
    )
    return response
  },
  async updateAddress({ id, data, originalId }: UpdatedLocation) {
    const upperCaseProvinceCountryCodes = upperProvinceCountryCodes(data)
    try {
      const { data: response } = await patch(
        api.locations.id.default,
        upperCaseProvinceCountryCodes,
        { entities: { id } }
      )
      return response
    } catch (e: any) {
      if (e.response?.data) {
        // TODO - we will be checking the error code when backend start sending error code - Ticket needs to be created
        // The reason behind checking for the isActive message is because when we are following non linear navigation
        // the location id which was active during first time will be inactive and new location id will be created but
        // original id will be same - so we will be fetching the active address for given originalId and
        // since there will always be one active address we will be grabing that location id
        // and patching that
        if (
          e.response.data.statusCode === 403 &&
          e.response.data.messages ===
            'Cannot update a location that has `isActive` set to false' &&
          originalId
        ) {
          const { data: locationsData } = await get(api.locations.default, {
            limit: 1,
            originalId,
          })

          if (locationsData && locationsData.locations[0]) {
            const { data: response } = await patch(
              api.locations.id.default,
              upperCaseProvinceCountryCodes,
              { entities: { id: locationsData.locations[0].id } }
            )
            return response
          }
        }
      }
    }
  },
  async deleteAddress({ id }: { id: string }) {
    const { data: response } = await deleteReq(
      api.locations.id.default,
      undefined,
      {
        entities: { id },
      }
    )
    return response
  },
  async validateAddress(
    payload: ValidateRequest
  ): Promise<ValidateAddressResponse> {
    const { data } = await get(
      api.locations.validate,
      upperProvinceCountryCodes(payload)
    )
    return data
  },
  async fetchCountryList(): Promise<Record<string, CountriesOutput>> {
    const { data } = await get(api.locations.countries)
    return data
  },
  async fetchDocs(payload: DocumentFilterParams): Promise<DocumentsResponse> {
    const { data } = await get(api.locations.document, payload)
    return data
  },
  async uploadBusinessLicenseDocument(data: any) {
    const response = await post(api.locations.document, data)
    return response
  },
  async fetchNationalExemptionReqs(country: string): Promise<CountryOutput> {
    const { data } = await get(api.locations.exemptionReqs.country, null, {
      entities: { country },
    })
    return data
  },
  async fetchExemptionReqs({
    country,
    region,
  }: {
    country: string
    region?: string
  }) {
    console.warn('`fetchExemptionReqs` is deprecated. Use ')
    const { data } = await get(
      api.locations.exemptionReqs[region ? 'countryRegion' : 'country'],
      null,
      { entities: { ...(region ? { region } : {}), country } }
    )
    return data
  },
  async generateResaleCertificate(
    image: Blob | null,
    query: GenerateCertificateParams
  ) {
    const formData = new FormData()
    if (image) {
      formData.append('image', image)
    }
    const { data } = await post(
      api.locations.generateResaleCertificate,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: query,
        responseType: 'blob',
      }
    )
    return data
  },
}
