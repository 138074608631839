import { memo, useMemo } from 'react'

import type { History } from 'history'
import type { RouteProps } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { styled } from 'styled-components'

import Spinner from '@components/ui/spinner/Spinner'
import useAuthCheck from '@helpers/hooks/useauthcheck/useAuthCheck'
import ScrollMemory from '@helpers/ScrollMemory'

import { HandlePageConditionalRender } from '../Routes'

export const StyledSpinner = styled(Spinner)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-content: center;
`

export const SpinnerContainer = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
`

export const AuthSpinner = () => (
  <SpinnerContainer>
    <StyledSpinner text={false} />
  </SpinnerContainer>
)

const PrivateRoute = ({
  exact,
  path,
  component,
  render,
  ...props
}: Omit<RouteProps, 'component'> & { component?: React.ReactNode }) => {
  const isLoading = useAuthCheck()

  const handleRender = useMemo(
    () =>
      render ||
      (({ history }: { history: History }) => (
        <>
          <ScrollMemory />
          <HandlePageConditionalRender
            history={history}
            component={component}
          />
        </>
      )),
    [render, component]
  )

  if (isLoading) {
    return <AuthSpinner />
  }

  return <Route exact={exact} path={path} render={handleRender} {...props} />
}

export default memo(PrivateRoute)
