import type { LogsInitConfiguration, StatusType } from '@datadog/browser-logs'

import modulesApi from './modules/api'
import serverConfig from '../../server/config'

const getConfig = () => {
  const api = modulesApi

  const site = {
    TITLE: 'B-Stock Registration',
  } as const

  const appName = 'accounts-portal'
  const platform = '3MP'
  const env = process.env.APP_ENV
  if (!env) {
    throw new Error('APP_ENV was not set')
  }
  const askMeUrl = process.env.SERVICE_HOST_ASKME
  const askMeSiteAbb = process.env.ASKME_SITE_ABB
  const supplyURLBase = process.env.SUPPLY_URL_BASE

  const sentry = {
    enabled: false,
    url: 'https://c768a0ff88fd4b2d91f2bd6e1afbb949@sentry.io/1491176',
  } as const

  const stripeKey = process.env.STRIPE_PUBLIC_KEY
  if (!stripeKey) {
    throw new Error('STRIPE_PUBLIC_KEY was not set')
  }
  const stripe = {
    pKey: stripeKey,
  } as const

  const segment = {
    wKey:
      process.env.REACT_APP_SEGMENT_WRITE_KEY ||
      'dEuLO252MjKUyHynJkJHKb0hspJd2QXl',
  }

  const datadogToken = process.env.DATADOG_CLIENT_TOKEN
  if (!datadogToken) {
    throw new Error('DATADOG_CLIENT_TOKEN was not set')
  }
  const datadog: LogsInitConfiguration & {
    level: StatusType
    enabled: boolean
  } = {
    clientToken: datadogToken,
    version: process.env.BUILD_ID || 'unspecified',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error'],
    sessionSampleRate: 100,
    level: process.env.LOGGER_LEVEL as StatusType,
    enabled: !!process.env.DATADOG_LOGS && process.env.DATADOG_LOGS !== 'false',
  }

  const googleTagManager = {
    enabled: true,
    trackingId: 'GTM-TBWFRXW',
    consoleLogEvents: false,
  } as const

  // product wants 256 but FusionAuth fails at over 75
  const emailMax = 75

  return {
    ...serverConfig,
    api,
    site,
    appName,
    platform,
    env,
    askMeUrl,
    askMeSiteAbb,
    supplyURLBase,
    sentry,
    stripe,
    segment,
    datadog,
    googleTagManager,
    emailMax,
    serviceHosts: api.serviceHosts,
  }
}
export type Config = ReturnType<typeof getConfig>

declare global {
  interface Window {
    FORWARD_CONFIG?: Config
  }
}

const config =
  typeof window !== 'undefined' && window.FORWARD_CONFIG
    ? window.FORWARD_CONFIG
    : getConfig()

export default config
