import type { Site } from '@b-stock/account-api-client'

import appConfig from '@config/config'
import { get, post } from '@helpers/xhr'

const { api } = appConfig

export type SitesDataResponse = Partial<
  Record<
    string,
    {
      siteAbb: string
      isComplete: boolean
    }
  >
>

export type SitesResponse = Partial<Record<string, Site>>

type AddMarketplace = {
  id: string
  siteAbb: string
}

type AddMarketplaceDataResponse = {
  status: number
  data: {
    refreshToken: string
    siteAbb?: string | null | undefined
    isComplete: boolean
    inReview: boolean
  }
}

export default {
  async getSites(): Promise<SitesResponse> {
    const { data: response } = await get(api.accounts.sites.getSites)
    return response
  },
  async getSite({ siteAbb }: Site): Promise<Site | undefined> {
    return (await this.getSites())[siteAbb as string]
  },
  // blocked by ZRO-2144
  async addMarketplace({
    id,
    siteAbb,
  }: AddMarketplace): Promise<AddMarketplaceDataResponse> {
    const response = await post(
      api.accounts.sites.addMarketplace,
      { siteAbb },
      { entities: { id } }
    )
    return response
  },
}
