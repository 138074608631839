// This entire file is deprecated and will eventually be removed
/* eslint-disable @typescript-eslint/no-deprecated */

import {
  breakpoints,
  bstockColors,
  designColors,
  bstockFonts,
  layouts,
  typography,
} from '@b-stock/bstock-react/theme'

/** @deprecated Use designColors instead */
const colors = {
  ...bstockColors,
  primary: '#DD4542',
  warning: '#FB840B',
  warning2: '#FFF5E3',
  informationBg: '#F2F7FE',
  black0: '#000',
  black1: '#141A1E',
  black2: '#232323',
  black3: '#4F5355',
  red: '#CE0000',
  red2: '#FB3640',
  red3: '#EE0202',
  gray7: '#979797',
  gray8: '#787878',
  gray9: '#4f5355',
  gray10: '#818181',
  gray11: '#E6E6E6',
  gray12: '#6D6D6D',
  gray13: '#3F3F3F',
  gray14: '#6D6D6D',
  gray15: '#D7D7D7',
  gray16: '#F4F4F4',
  gray17: '#DADCE0',
  gray18: '#A3AAB0',
  gray19: '#424A58',
  gray20: '#78828A',
  gray22: '#DDE7F4',
  gray23: '#E7F7F4',
  gray24: '#F0F0F0',
  gray25: '#332324',
  // 26-28 Temporary Supply colors
  gray26: '#EDEDED',
  gray27: '#FBFBFB',
  gray28: '#CCCCCC',
  green2: '#D4EDDA',
  green3: '#C3E6CB',
  green4: '#0C5460',
  green5: '#0C5555',
  green6: '#4ED985',
  green7: '#347846',
  green8: '#DAF8E2',

  blue: '#153ADC',
  'light-blue': '#81B3FF',
  'light-blue2': '#E7F6FF',
  blue1: '#153adc',
  blue2: '#B0BDF0',
  blue3: '#1F60BA',
  blue4: '#FAFBFF',
  blue5: '#F6FAFF',
  blue6: '#06238B',
  tip: '#F9FBFF',
  border: '#E1E1E1',
  disabled: '#F6F7FA',

  pink1: '#FEE1E2',
  orange: '#FFF5EA',
  // Color generator for svg: https://codepen.io/sosuke/pen/Pjoqqp
  blue1ForSvg:
    'invert(25%) sepia(68%) saturate(3854%) hue-rotate(226deg) brightness(81%) contrast(116%)',
} as const

/** @deprecated Use typography instead */
const fonts = {
  ...bstockFonts,
  proxima: `
    font-family: Proxima Nova;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
  `,
} as const

// Generated: https://css-tricks.com/more-control-over-css-borders-with-background-image/
type GenerateDashedBorderArg = {
  color?: string
  length?: number
  gap?: number
}
export const generateDashedBorder = ({
  color = colors.gray2,
  length = 19,
  gap = 11,
}: GenerateDashedBorderArg) => {
  const spacing = +length + gap

  return `
    background-image: repeating-linear-gradient(
        28deg,
        ${color},
        ${color} ${length}px,
        transparent ${length}px,
        transparent ${spacing}px,
        ${color} ${spacing}px
      ),
      repeating-linear-gradient(
        118deg,
        ${color},
        ${color} ${length}px,
        transparent ${length}px,
        transparent ${spacing}px,
        ${color} ${spacing}px
      ),
      repeating-linear-gradient(
        208deg,
        ${color},
        ${color} ${length}px,
        transparent ${length}px,
        transparent ${spacing}px,
        ${color} ${spacing}px
      ),
      repeating-linear-gradient(
        298deg,
        ${color},
        ${color} ${length}px,
        transparent ${length}px,
        transparent ${spacing}px,
        ${color} ${spacing}px
      );
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
  `
}

const ui = {
  'no-select': `
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `,

  borderRadius: '.3125rem',
  dashedBorder: generateDashedBorder({}),
  activeDashedBorder: generateDashedBorder({ color: colors.blue6 }),
} as const

export default {
  ui,
  fonts,
  colors,
  designColors,
  layouts,
  typography,
  breakpoints,
}

declare module 'styled-components' {
  export interface DefaultTheme {
    ui: typeof ui
    fonts: typeof fonts
    colors: typeof colors
    designColors: typeof designColors
    layouts: typeof layouts
    typography: { [key: string]: string }
    breakpoints: typeof breakpoints
  }
}
