import type {
  PeopleApiGetPeopleRequest,
  Person,
  UpdatePersonDto,
} from '@b-stock/account-api-client'

import appConfig from '@config/config'
import { get, patch, post } from '@helpers/xhr'

const { api } = appConfig

export default {
  async fetchPerson(id: string): Promise<Person> {
    const { data } = await get(api.people.id.default, null, {
      entities: { id },
    })

    return data
  },

  async updatePerson({
    id,
    data: payload,
  }: {
    id: string
    data: UpdatePersonDto
  }): Promise<Person> {
    const { data } = await patch(api.people.id.default, payload, {
      entities: { id },
    })
    return data
  },

  async fetchPersons(payload: PeopleApiGetPeopleRequest) {
    const { data } = await get(api.people.default, payload)
    return data
  },

  async resendValidationEmail({ id }: { id: string }): Promise<void> {
    await post(api.people.id.resendValidationEmail, {}, { entities: { id } })
  },

  async verifyEmail({
    id,
    verificationCode,
  }: {
    id: string
    verificationCode: string
  }): Promise<void> {
    await post(
      api.people.id.verifyEmail,
      { verificationCode },
      { entities: { id } }
    )
  },
}
