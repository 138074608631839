import * as Sentry from '@sentry/browser'
import queryString from 'query-string'
import { put, takeLatest, call } from 'redux-saga/effects'

import { IdentityProviderType } from '@b-stock/account-api-client'
import { ddLogger } from '@b-stock/bstock-react'

import signUpApi from '@api/signUp'
import appConfig from '@config/config'
import { sanitizeEmail } from '@helpers/sanitize'

import type { SubmitEmailValues } from './types'
import types from './types'
import signupTypes from '../signUp/types'

const { api, baseUrl } = appConfig

const {
  oauth2,
  salesforce,
  auth: { clientId },
} = api

const { checkUserExists } = signUpApi

export function* submitEmailSaga({
  values: { email, ...rest },
}: {
  type: string
  values: SubmitEmailValues
}) {
  const sanitizedEmail = sanitizeEmail(email)
  try {
    yield put({ type: types.SIGN_IN_SUBMIT_EMAIL_PENDING })
    // if user is from magento
    const { isEnterpriseUser = false } = rest
    if (isEnterpriseUser) {
      const params = queryString.stringify({
        client_id: clientId,
        redirect_uri:
          typeof document === 'undefined'
            ? 'Unable to determine redirect uri!'
            : `${document.location.origin}${baseUrl}/login`,
        response_type: 'code',
        scope: 'offline_access',
        email: sanitizedEmail,
        state: rest ? JSON.stringify(rest) : undefined,
      })
      window.location.assign(`${oauth2.authorize}?${params}`)
      return
    }
    const {
      hasCredentials,
      isEmailVerified,
      identityProviderType,
      identityProviderUserId,
    } = yield call(checkUserExists, { email: sanitizedEmail })

    // If the user has created an account and login credentials, OR BE
    // identified them as a possible salesforce and are elegible for
    // auto-provisioning, redirect to the relevant identity provider.
    if (
      (isEmailVerified && hasCredentials) ||
      identityProviderType === IdentityProviderType.Salesforce
    ) {
      yield put({
        type: types.SIGN_IN_SUBMIT_EMAIL_FULFILLED,
        email: sanitizedEmail,
      })

      // Note: I'm pretty sure this is dead code, but out of an abundance of
      // caution, I'm leaving it in until we can prove it's not used by
      // anything. - Ryan, 2024-03-12
      const r = rest as Record<string, unknown>
      if (r.password || r.verificationCode) {
        ddLogger.error(
          "A password or verification code was submitted to the sign in saga. This indicates an error in the saga's caller."
        )
        delete r.password
        delete r.verificationCode
      }

      // send the user to the appropriate identity provider
      if (identityProviderType === IdentityProviderType.Fusionauth) {
        const params = queryString.stringify({
          client_id: clientId,
          redirect_uri:
            typeof document === 'undefined'
              ? 'Unable to determine redirect uri!'
              : `${window.location.origin}${baseUrl}/login`,
          response_type: 'code',
          scope: 'offline_access',
          email: sanitizedEmail,
          state: JSON.stringify({ role: 'buyer', ...rest }),
        })
        window.location.assign(`${oauth2.authorize}?${params}`)
        return
      } else if (identityProviderType === IdentityProviderType.Salesforce) {
        const params = queryString.stringify({
          client_id: salesforce.clientId,
          redirect_uri:
            typeof document === 'undefined'
              ? 'Unable to determine redirect uri!'
              : `${window.location.origin}${baseUrl}/login/salesforce`,
          response_type: 'code',
          state: JSON.stringify(rest),
          ...(identityProviderUserId
            ? { login_hint: identityProviderUserId }
            : {}),
        })
        window.location.assign(`${salesforce.authorize}?${params}`)
        return
      } else {
        throw new Error(
          `Unexpected identityProviderType: ${identityProviderType}`
        )
      }
    } else if (isEmailVerified && !hasCredentials) {
      yield put({
        type: signupTypes.CHECK_EMAIL_FULFILLED,
        payload: { email: sanitizedEmail, isEmailVerified, hasCredentials },
      })
    } else {
      yield put({
        type: types.SIGN_IN_SUBMIT_EMAIL_REJECTED,
        email: sanitizedEmail,
        error: true,
      })
    }
  } catch (error) {
    yield put({
      type: types.SIGN_IN_SUBMIT_EMAIL_REJECTED,
      error,
    })
    console.error(error)
    Sentry.captureException(error)
  }
}

export default function* signinSaga() {
  try {
    yield takeLatest(types.SIGN_IN_SUBMIT_EMAIL, submitEmailSaga)
  } catch (error) {
    ddLogger.error(
      'SignIn error',
      {},
      error instanceof Error
        ? error
        : new Error('Original error did not have a stack trace')
    )
  }
}
