import axios from 'axios'
import reduce from 'lodash-es/reduce'

import { getAccessToken } from '@helpers/accessToken'

function applyEntities(url, data) {
  if (!data) {
    return url
  }

  return reduce(data, (str, value, key) => str.replace(`:${key}`, value), url)
}

async function apiCall(url = '', data = null, options = {}) {
  const {
    method = 'GET',
    params,
    headers: additionalHeaders,
    responseType = 'json',
    ...rest
  } = options
  const accessToken = await getAccessToken()
  const preparedUrl = applyEntities(url, options.entities)

  const headers = {
    'Content-Type': 'application/json',
    // comment out line 32-42 as workaround when running BE locally
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    ...additionalHeaders,
  }

  const config = {
    method, // *GET, POST, PUT, DELETE, etc.
    // mode: 'no-cors', // set as no-cors as workaround when running BE locally
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    responseType, // json, arraybuffer, document, text, stream
    params,
    ...(data ? { data } : {}), // body data type must match 'Content-Type' header
    ...rest,
  }

  return axios(preparedUrl, config)
}

export function get(url, params, options) {
  return apiCall(url, undefined, { params, ...options })
}

export function post(url, data = {}, options) {
  return apiCall(url, data, { method: 'POST', ...options })
}

export function patch(url, data = {}, options) {
  return apiCall(url, data, { method: 'PATCH', ...options })
}

export function put(url, data = {}, options) {
  return apiCall(url, data, { method: 'PUT', ...options })
}

export function deleteReq(url, data = {}, options) {
  return apiCall(url, data, { method: 'DELETE', ...options })
}
